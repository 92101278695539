<template lang="pug">
  div
    div
      v-btn(
        v-if="reservedExam && btnResultLabel"
        @click="showResult"
        small
        outlined
        color="primary"
        data-test="results-show") {{ btnResultLabel }}
    div
      v-btn(
        v-if="canLoadData || statusLoaded || statusFailed"
        :color="PURPLE"
        outlined
        @click="showModal"
        small
        data-test="results-set"
        :loading="loading"
      ).text-capitalize Set
    v-dialog(v-model="showing" max-width="500" scrollable)
      v-card(data-test="results-info-dialog").exam-card
        v-card-title.headline {{ candidateName }} results:
        v-divider
        v-card-text(v-if="result" data-test="edit-form").exam-card__container
          h3.form-title Main info
          p.text-box
            span.text-box__title Results:
            span.text-box__content
              v-select(
                data-test="results-field"
                v-model="result.results"
                :items="options"
                label="Choose result"
                :error="formErrors.hasError('results')"
                :error-messages="formErrors.fields.results"
              )
          p.text-box
            v-checkbox(v-model="result.has_zero_mistakes" label="Zero mistakes" hide-details)
          h3.form-title(v-if="mistake") Mistake:
          p.text-box(v-if="mistake")
            span.text-box__content(v-html="mistake" )
        v-card-actions
          v-spacer
          v-btn(data-test="save-btn" color="primary" @click="saveResults") save
          v-btn(text color="primary" @click="hideModal") close
</template>

<script>
import showingMixin from '@/mixins/showing.mixin'
import errorsMixin from '@/mixins/errors.mixin'
import ResultReservedExam, { ResultReservedExamFormValidationFields } from '@/app/admin/modules/results/core/models/resultReservedExam'
import { RESULT_TYPES, PURPLE } from '@/app/admin/modules/results/core/results-const'
import FormErrors from "@/util/form-errors"
import { RESULT_STATUS } from '@/app/admin/modules/results/core/models/resultReservedExam'

export default {
  mixins: [errorsMixin, showingMixin],

  props: {
    itemID: Number,
    reservedExam: Object,

    candidateName: {
      type: String,
      default: 'name'
    }
  },

  inject: ['svc'],

  data: () => ({
    loading: false,
    PURPLE,
    options: Object.values(RESULT_TYPES),
    result: null,
    formErrors: null,
  }),

  mounted() {
    this.init()
  },

  computed: {
    btnResultLabel() {
      if (!this.reservedExam || !this.reservedExam.results[0]) return null
      return this.reservedExam.results[0].toUpperCase()
    },

    mistake() {
      if (this.statusInit) return null
      if (!this.reservedExam) return null
      return this.reservedExam.mistake.replace(/\n/g, '<br />')
    },

    canLoadData() {
      if (!this.reservedExam) return true
      return false
    },

    statusInit() {
      if (!this.reservedExam) return true
      return this.canBeLoaded
    },

    statusFailed() {
      if (this.reservedExam && this.reservedExam.status === RESULT_STATUS.FAILED) return true
      return false
    },

    statusLoaded() {
      if (this.reservedExam && this.reservedExam.status === RESULT_STATUS.FETCHING) return true
      return false
    },
  },

  watch: {
    'showing'(value) {
      if (value) {
        this.init()
      }
    }
  },

  methods: {
    init() {
      this.resetErrors()
      this.result = this.reservedExam ?
        new ResultReservedExam({...this.reservedExam, has_zero_mistakes: this.reservedExam.has_zero_mistakes}) :
        new ResultReservedExam({})
    },

    resetErrors() {
      this.formErrors = new FormErrors();
    },

    async saveResults() {
      try {
        this.loading = true
        await this.svc.saveResults(this.itemID, this.result.getApiData())
        this.hideModal()
      } catch (error) {
        console.log(error, 'err')
        this.processErrorWithForm(error,{
          formErrors: this.formErrors,
          redirectName: this.$ROUTER_NAMES.LOGIN_CRM,
          apiAttributes: new ResultReservedExamFormValidationFields()
        })
      } finally {
        this.loading = false
      }
    },

    showResult() {
      this.showModal()
    },

    showModal() {
      this.updateShowing(true)
    },

    hideModal() {
      this.updateShowing(false)
    },
  }
}
</script>

<style lang="scss" scoped>
.text-box {
  display: flex;
  align-items: center;
  margin-top: 0;
  margin-bottom: 5px;

  &__content {
    margin-left: 8px;
    color: #000000;
  }
}

</style>

<style lang="scss">
.exam-card {
  &__container {
    padding-top: 10px !important;
  }
}

</style>
